import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { MenuItems, SEO } from '../../dictionary/dictionary.json';

const { StudentsT } = SEO;

import './students.css';
import { LOG_IN } from '../../actions/types';

const Students = props => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const renderPage = () => {
        if (props.user.displayName !== process.env.REACT_APP_STUDENTS) {
            return (
                <div>
                    <h1>הכניסה לתלמידים רשומים בלבד</h1>
                </div>
            );
        }
        return (
            <React.Fragment>
                <Link to={'/students/computerTalan'} className="student-btn">
                    צעדים ראשונים של הדור הבא
                </Link>
                <Link
                    to={'/students/programmingScratch'}
                    className="student-btn"
                >
                    קורס מתכנתי הדור הבא
                </Link>
                <Link
                    to={'/students/programmingMakeCode'}
                    className="student-btn"
                >
                    קורס המייקרים של הדור הבא
                </Link>
                <Link
                    to={'/students/programmingAdvance'}
                    className="student-btn"
                >
                    קורס יזמי הדור הבא
                </Link>
                <Link
                    to={'/students/programmingPython'}
                    className="student-btn"
                >
                    קורס פייתון
                </Link>
                <Link
                    to={'/students/programmingAppInventor'}
                    className="student-btn"
                >
                    קורס ממציא האפליקציות
                </Link>
                <Link
                    to={'/students/programmingWebDev'}
                    className="student-btn"
                >
                    קורס פיתוח אתרים
                </Link>
            </React.Fragment>
        );
    };
    return (
        <div>
            <Helmet>
                <title>{StudentsT.title}</title>
                <meta name="description" content={StudentsT.desc} />
                <meta name="title" content={StudentsT.title} />
            </Helmet>
            <div className="students-container">{renderPage()}</div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}
export default connect(mapStateToProps)(Students);
