import React from 'react';

const ScratcherPart2 = () => {
    return (
        <div style={{ textAlign: 'center', padding: '20px', color: 'black' }}>
            <h1>עדכונים למדריך לסקראצ'רים חלק 2</h1>
            <h3>אין כרגע עדכונים</h3>
        </div>
    );
};
export default ScratcherPart2;
